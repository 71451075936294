import { PageProps } from 'gatsby';
import { FC } from 'react';
import {
  Aside,
  ContentSection,
  DeepDive,
  DeepDiveButton,
  DeepDiveSection,
  Footnotes,
  H3,
  HeadlinePadding,
  Hero,
  HR,
  Layout,
  RemoveInternalPadding,
} from '~/components';
import { Frontmatter } from '~/config';
import image from '~/images/mary-top-ebc.jpg';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Example of an internal page',
  orderInLib: 20,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <Hero>This is a very important Hero</Hero>

      <Hero backgroundImage={image} headlinePadding={HeadlinePadding.lg}>
        So is this, but <em>this one</em> has a bg image
      </Hero>

      <HR yellow />
      <DeepDive>
        <DeepDiveSection>
          <h2>Section One</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem, placeat quod cum sapiente odio, quos explicabo
            dolore rerum et? Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Cupiditate architecto assumenda adipisci! Nobis dicta est
            recusandae! Eaque provident ipsa sapiente, quisquam iste at magni
            explicabo quae et consequuntur maiores sequi?
          </p>

          <Aside>
            <DeepDiveButton>Learn about things</DeepDiveButton>
          </Aside>
        </DeepDiveSection>
        <DeepDiveSection isDeepDive>
          <h2>Section II</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem
          </p>

          <Aside left>
            <DeepDiveButton direction="climb-out">
              I've seen enough
            </DeepDiveButton>
          </Aside>
        </DeepDiveSection>
      </DeepDive>

      <HR yellow />
      <ContentSection>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus
          asperiores dolor, voluptatibus aut molestias, dolorem minus
          exercitationem reiciendis fugit odit atque aperiam nam ipsam eveniet
          natus, commodi minima obcaecati corporis.
        </p>

        <H3 normalCase>Footnotes</H3>
        <Footnotes
          footnotes={['randomized', 'recurrence', 'absolute-benefit']}
        />

        <H3 normalCase>Footnotes (Left)</H3>
        <Footnotes
          footnotes={['randomized', 'recurrence', 'absolute-benefit']}
          alignment="left"
        />

        <H3 normalCase>Footnotes (Right)</H3>
        <Footnotes
          footnotes={['randomized', 'recurrence', 'absolute-benefit']}
          alignment="right"
        />

        <RemoveInternalPadding>
          {/* Sample of small text sorta link refs */}
          <p tw="text-xs mt-24 border-2">
            <strong>This area doesn't have internal padding.</strong>
            Also, lorem ipsum dolor sit amet consectetur adipisicing elit.
            Corporis tempora quas nostrum beatae rem debitis eligendi provident
            maxime esse exercitationem, placeat quod cum sapiente odio, quos
            explicabo dolore rerum et? Lorem ipsum dolor, sit amet consectetur
            adipisicing elit. Cupiditate architecto assumenda adipisci! Nobis
            dicta est recusandae! Eaque provident ipsa sapiente, quisquam iste
            at magni explicabo quae et consequuntur maiores sequi?
          </p>
        </RemoveInternalPadding>
      </ContentSection>
    </Layout>
  );
};

export default Page;
